import React, { useEffect } from "react";
import { Wrapper } from "../components/globalElements";
import Layout from "../components/Layout/Layout";
import { TextSplit } from "components";
import { LoginStyled } from "../styles/login.styles";
import OnboardFooter from "../components/Onboard/OnboardFooter";
import SignupForm from "../components/Onboard/SignupForm";
import { getCurrentUser } from "../utils/firebase/firebase.utils";
import { navigate } from "gatsby";
import { toast } from "react-toastify";

const CreateUser = () => {
	useEffect(() => {
		if (getCurrentUser() && getCurrentUser().email !== "admin@gmail.com") {
			toast.error("Sorry, you are not authorized to create a user");
			setTimeout(() => navigate("/"), 3000);
		}
	}, []);

	return (
		<Layout title="Create User Page" fullScreen={true} footer={false}>
			<Wrapper style={{ marginBottom: "5rem" }}>
				<TextSplit>
					<LoginStyled>
						<h1 className="heading">Create User Portal</h1>

						<SignupForm />
						<div className="middle-line"></div>

						<OnboardFooter />
					</LoginStyled>
				</TextSplit>
			</Wrapper>
		</Layout>
	);
};

export default CreateUser;
